/* You can add global styles to this file, and also import other style files */

@import "./variables";

html {
  overflow-x: hidden;
}
body {
  font-family: "Montserrat", sans-serif !important;
  overflow: auto;
  position: static;
  font-size: 15px !important;
  background: var(--grey-color) !important;
}

input[type="submit"] {
  background-color: var(--lightblue-color);
  color: var(--light-white-color);
  width: 140px;
  height: 44px;
  border-radius: 50px;
}
.submit-row {
  padding-top: 1.4rem;
}
h2.heading {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
h6.heading {
  font-size: 20px;
  font-weight: 600;
  color: var(--darkblue-color);
}
.custom-btn {
  border-radius: 5px !important;
  color: #fff !important;
  font-size: 0.88rem !important;
  font-weight: 500 !important;
  border: 0 !important;
  padding: 10px !important;
  margin-left: 1rem;
}
.custom-btn.size-1 {
  width: 11rem;
}
.custom-btn.size-2 {
  width: 13rem;
}
.btn-blue {
  background: var(--lightblue-color) !important;
}
.btn-green {
  background: var(--green-color) !important;
}
.btn-green-50 {
  background-color: var(--green-color-50) !important;
}
.btn-dark-blue {
  background-color: var(--darkblue-color) !important;
}
.color-blue {
  color: var(--darkblue-color);
}
.bg-grey {
  background: var(--light-grey-color);
}
.text-black {
  color: #000;
}
.blue-text p {
  color: var(--darkblue-color);
}
.sections p {
  font-weight: 500;
}
.btns {
  margin: 2rem 0 0 0;
}
.btns button {
  margin: 0 1rem 0 0 !important;
  box-shadow: 0px 4px 12px 3px rgb(62 62 62 / 30%);
}
.img-bottom {
  margin-bottom: -29%;
}
.s-pad {
  padding: 6rem 0;
}
.img-100-per {
  width: 100%;
}
.ul-style {
  padding: 0;
  margin: 1.3rem 0 1.3rem 0;
  line-height: 1.8;
}
.ul-style li {
  list-style: none;
  display: flex;
  // align-items: center;
  color: var(--darkblue-color);
  font-size: 0.87rem;
  font-weight: 500;
}
.ul-style li:before {
  content: "";
  border: 0.1rem solid var(--darkblue-color);
  width: 9px;
  height: 9px;
  display: flex;
  border-radius: 100%;
  margin-right: 0.8rem;
  margin-top: 8px;
}
.how-it-works {
  .howitworks-box {
    background-color: var(--blue-color-50);
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 23px;
    // box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    box-shadow: 0 16px 23px 0 rgba(0, 0, 0, 0.08);
    height: 100%;
    width: 96%;
    position: relative;

    &::after {
      content: "";
      // background:url(/../assets/images/arrow1.svg);
      position: absolute;
      width: 23px;
      height: 16px;
      right: -34px;
      top: 50%;
      background-repeat: no-repeat;
    }

    span {
      color: var(--darkblue-color);
      font-size: 1.8rem;
      font-weight: 500;
    }
    img {
      // height: 130px;
      margin: 0rem auto;
    }
    p {
      text-align: center;
    }
  }
}
.color-green {
  color: var(--blue-color-100);
}
.how-it-works .howitworks-box.last:after {
  display: none;
}
.semi-bold {
  font-weight: 600 !important;
}
.s-benefits .p-left {
  padding-left: 3rem;
}
.s-benefits .p-right {
  padding-right: 3rem;
}
.bg-grey-2 {
  background-color: var(--grey-color-200);
}
.relates-news {
  padding: 1.3rem;
  h3 {
    font-size: 24px;
    color: var(--darkblue-color);
    font-weight: bold;
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: bold;
    font-size: 19px;
    color: var(--darkgray-color);
    margin-top: 1rem;
  }
  p {
    color: var(--grey-color-50);
    font-size: 14px;
    line-height: 23px;
  }
  .side-news-list {
    margin-bottom: 2rem;
  }
}
h4 a {
  color: var(--darkblue-color);
  text-decoration: none;
  &:hover {
    color: var(--darkblue-color);
  }
}
// News

// Privacy and footer-style
.privacy-policy {
  h3 {
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-top: 3rem;
  }
  h5 {
    font-weight: 600;
    margin-top: 1rem;
  }
  .table-style {
    width: 100%;
  }
  table tr td,
  table tr th {
    padding: 0.5rem;
    vertical-align: top;
  }
  .table-style td,
  .table-style th {
    border: 1px solid var(--darkgray-color);
  }
  li {
    margin-top: 1rem;
  }
  li::marker {
    font-weight: 600;
    margin-right: 1rem;
  }
}
.custom-btn {
  a {
    text-decoration: none;
  }

  .color-black {
    color: #000;
  }
  .color-white {
    color: #fff;
  }
}
.converter-box input {
  appearance: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.converter-box {
  .container {
    position: relative;
    z-index: 2;
  }
}
app-footer {
  position: relative;
  z-index: 1;
}
.relates-news {
  padding: 1.3rem;
  position: fixed;
  width: 27%;
  top: 150px;
  height: calc(100vh - 190px);
  // z-index: -1;ng
  overflow: auto !important;
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 2px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  img.img-100-per {
    height: 179px;
    object-fit: cover;
  }
}
.converter-box .select-heading {
  color: #0034fb;
  font-weight: 600;
  padding: 0 15px;
  margin-top: 14px;
}

@media screen and (min-width: 1024px) {
  .desktop-img-100per {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .custom-btn.size-1 {
    width: 8rem;
  }
  .mob-100-per {
    width: 100%;
  }
  h2.heading {
    font-size: 1.5rem;
  }
  .custom-select img {
    margin-right: 0.5rem !important;
    width: 50px;
  }
  .converter-container {
    .spendbox {
      input {
        font-size: 1.4rem !important;
      }
    }
  }
  .converter-box {
    .container {
      position: relative;
      z-index: 2;
    }
  }
}
@media screen and (max-width: 1023px) {
  .custom-btn.size-1 {
    width: 6rem;
  }
  .drop-box.vs img {
    width: 57px;
  }
  .btns .custom-btn.size-1 {
    width: 9rem;
  }
  .pad--mob {
    padding: 6rem 0;
  }
  .app-download img {
    width: 90px;
  }
  .navbar-toggler,
  #navbarNavDropdown {
    // display: none !important;
  }
  .img-bottom {
    margin: 0;
  }
  .heading.pb-5 {
    padding-bottom: 1rem !important;
  }
  .mob-center {
    text-align: center;
  }
  .mob-pad-0 {
    padding-top: 30px !important;
  }
}
@media screen and (max-width: 767px) {
  .pad--mob {
    padding: 0;
  }
  .mob-center {
    text-align: center;
  }

  .sections {
    padding: 3rem 0 !important;
  }
  .pico-way {
    padding: 0 !important;
  }
  .heading.pb-5 {
    margin-bottom: 0 !important;
  }
  .bgimg {
    padding: 0 !important;
  }
  .s-benefits .p-left {
    padding: 1rem !important;
  }
  .s-benefits .p-right {
    padding-right: 1rem;
  }
  .s-benefits .row {
    margin: 0 0 1rem 0 !important;
  }
  .mob-100-per {
    width: 70%;
    margin: 0 !important;
  }
  .col-rev-mob {
    flex-direction: column-reverse;
  }
  .how-it-works .howitworks-box {
    height: auto;
    margin-bottom: 3rem;
    width: 100%;
  }
  .how-it-works .howitworks-box::after {
    top: auto;
    left: 47%;
    bottom: -34px;
    transform: rotate(90deg);
  }
  .app-download img {
    width: 50%;
  }
  .barcode {
    width: 30%;
  }
  .mob-mt0 {
    margin-top: 0 !important;
  }
  .converter-box .container {
    position: relative;
    z-index: 9;
  }
  .converter-box {
    padding: 2rem 0 !important;
  }
  .btns .custom-btn.size-1 {
    width: 44%;
  }
  .sections {
    text-align: center;
  }
  .s-benefits {
    text-align: left;
  }
  .ul-style {
    text-align: left;
  }
  .faq-section {
    text-align: left;
  }
  .country-curre {
    text-align: left;
  }
  .download-app {
    text-align: center;
  }
  .converter-box {
    text-align: center;
  }
  .converter-container {
    text-align: left;
  }
  .spendbox {
    text-align: left;
  }
}
@media screen and (max-width: 340px) {
  .btns {
    display: flex;
  }
  .s-benefits .row {
    display: flex;
    flex-direction: column;
    .col-3,
    .col-9 {
      width: 100%;
      .mob-100-per {
        width: 30%;
        margin-bottom: 1rem !important;
      }
    }
  }
  .app-download a {
    margin-right: 0.2rem !important;
  }
  .app-download .barcode {
    margin-right: 1rem !important;
  }
  .s-benefits {
    text-align: center;
  }
  .app-download img {
    width: 44%;
  }
}

// Loader Start
.loader-style {
  margin-top: 0px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* display: flex !important; */
  align-items: center;
  /* justify-content: center; */
  border-radius: 3rem;
  z-index: 999999;
  .spinner-border {
    --bs-spinner-width: 1.7rem !important;
    --bs-spinner-height: 1.7rem !important;
    margin-right: 15px;
    --bs-spinner-border-width: 0.1em;
    // border: var(--bs-spinner-border-width) solid #b3b2b2;
  }
}
.custom-loader {
  color: var(--ion-color-tertiary);
  background: #fff;
  padding: 1.5rem 2rem;
  border-radius: 11px;
  box-shadow: 0px 10px 14px 0px rgb(0 0 0 / 50%);
  display: flex;
  align-items: center;
  span {
    color: var(--ion-color-tertiary) !important;
  }
}
// Loader End

.custom-alert {
  position: absolute !important;
  top: -72px;
  left: 17%;
  z-index: 9;
  width: 69%;
}
.custom-close {
  position: absolute;
  top: -8px;
  color: #842029;
  border-radius: 100%;
  /* width: 20px; */
  /* height: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  font-weight: bold;
  font-size: 24px;
  right: 0;
}
.body-content {
  // height: 100%;
  margin: 1rem auto 6rem;
  // background: #fff;
  // border-radius: 0;
  // border-radius: 25px;
  // padding: 2.5rem;
  // overflow: hidden;
  // padding-top: 2rem;
  // padding-bottom: 5rem;
  // box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.14);
  // margin-bottom: 107px;
}

@media screen and (max-width: 768px) {
  .custom-alert {
    width: 100%;
    top: -33px;
    left: 0;
    // top:0;
  }
}
@media screen and (min-device-width: 700px) and (max-device-width: 1024px) {
  .custom-alert {
    top: -80px;
    width: 100%;
    left: 0;
  }
}
@media screen and (max-width: 1023px) and (orientation: landscape) {
  .custom-btn.size-1 {
    width: auto;
  }
  .fssteps-box {
    width: 100%;
  }
}

.modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  max-width: 600px;
  --border-radius: 16px;
  max-height: 480px;
}

ion-toolbar.sc-ion-searchbar-md-h,
ion-toolbar .sc-ion-searchbar-md-h {
  --border-radius: 6px;
}

.backdrop-no-scroll {
  .ion-page {
    overflow: hidden !important;
  }
}
.sc-ion-modal-md-h {
  .ion-color-primary {
    --ion-color-base: var(--ion-color-secondary) !important;
  }
}

#onfido-mount {
  //   height: 600px !important;
  .onfido-sdk-ui-Modal-inner {
    position: relative !important;
    height: auto !important;
    .onfido-sdk-ui-Theme-step {
      height: inherit !important;
    }
  }
  .onfido-sdk-ui-Theme-root {
    .ods-button.-action--primary {
      background: var(--ion-color-secondary) !important;
      border-color: var(--ion-color-secondary) !important;
      &:hover {
        background: rgba($color: $green-color, $alpha: 0.8) !important;
      }
    }
  }
}

#header {
  @include transition(all 3s ease-in-out);
  position: relative;
  position: sticky !important;
  top: 0;
  z-index: 9999;
  &.header-fixed {
    
    width: 100%;
    z-index: 9999;
  }
}

#app-container {
  //   position: initial !important;
  //   display: inline !important;
  //   overflow: hidden;
  // contain: none !important;
}

// body {
//   height: inherit !important;
//   position: initial !important;
// }
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
