@mixin border-radius-s($tl, $tr, $br, $bl) {
  border-top-left-radius: $tl;
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
}

@mixin border-radius-single($pixel) {
  border-radius: $pixel;
}

@mixin border-radius($pixel...) {
  border-radius: $pixel !important;
}

ion-content::part(scroll) {
  position: initial;
  padding: 0;
  overflow: visible;
  contain: initial;
}
ion-content {
  contain: inline-size !important;
}
html.ios {
  ion-content::part(scroll) {
    overflow: visible !important;
  }

  body.page-transaction {
    ion-content::part(scroll) {
      overflow-y: auto !important;
    }
  }
  .modal-wrapper {
    ion-content::part(scroll) {
      overflow-y: auto !important;
    }
  }
}
body,
html {
  scroll-behavior: smooth !important;
}
body.page-personal-reg,
body.page-pico-money-trasaction {
  ion-modal {
    padding: 5%;
  }
}

ion-header {
  ion-toolbar {
    background: none !important;
    ion-button {
      --background: none;
      --border-radius: 2px;
      --box-shadow: none;
      --padding-top: 10px;
      --padding-bottom: 10px;
      --padding-start: 10px;
      --padding-end: 10px;
      font-size: 13px;
      ion-icon {
        // color: var(--ion-color-dark);
        color: #000 !important;
        &::before {
          content: "";
          background: url(../assets/img/arrow-left.png);
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          display: block;
          background-size: 100%;
          background-position: center;
        }
      }
    }
    ion-title {
      font-weight: 600;
      font-size: 16px;
      color: var(--ion-color-step-50);
    }
  }
}
body.page-profile {
  ion-content {
    --background: transparent;
  }
}

ion-content {
  --background: var(--ion-color-dark-contrast);
  --padding-start: 20px;
  --padding-end: 20px;
}
ion-text {
  line-height: 1.5;
}
.my-custom-loader {
  --background: var(--ion-color-dark-contrast);
  --spinner-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary);

  .loading-wrapper.sc-ion-loading-md {
    @include border-radius-s(10px, 10px, 10px, 10px);
  }
}
.alert-wrapper.sc-ion-alert-ios {
  background: #f5f6fa !important;
}
ion-button {
  --ripple-color: rgba(var(--ion-color-primary-rgb), 0.3);
}
ion-back-button {
  --icon-font-size: 17px;
  max-width: 40px;
}
ion-grid {
  padding: 0;
}
ion-item {
  &.link-buttons-item {
    --background: none;
    --border-radius: 50px;
    --color: var(--ion-color-primary);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-end: 5px;
    --padding-start: 5px;
    --min-height: auto;
    --background-hover: none;
    margin-top: 15px;
    ion-label {
      text-align: center;
      font-size: 14px !important;
      margin: 0;
    }
  }
  --ripple-color: rgba(var(--ion-color-primary-rgb), 0.7);
  --detail-icon-opacity: 0.8;
  --detail-icon-font-size: 15px;
}
.box-shadow-blue {
  // box-shadow: 0px 3px 14px -3px rgba(var(--ion-color-primary-rgb), 0.3);
  box-shadow: 0px 3px 14px -3px rgba(var(--ion-color-step-rgb), 0.3);
}
.box-shadow-green {
  // box-shadow: 0px 3px 14px -3px rgba(var(--ion-color-tertiary-rgb), 0.3);
  box-shadow: none;
}
.box-shadow-light {
  box-shadow: 0px 3px 14px -3px #ccc;
}
.preferences-list {
  ion-item {
    --inner-padding-top: 5px;
    --inner-padding-bottom: 5px;
    font-size: 14px;
  }
}
.preferences-card {
  @include border-radius-single(15px);
  border: 1px solid #e4e4e4;
  box-shadow: 0px 0px 12px -6px #ccc;
  ion-item {
    --inner-padding-top: 12px;
    --inner-padding-bottom: 12px;
  }
}

.card-light--shadow {
  @extend .box-shadow-blue;
  @include border-radius-single(15px);
  margin: 0 0 15px;
}

.card-blue--shadow {
  @extend .box-shadow-blue;
  @include border-radius-single(15px);
  margin: 0 0 15px;
}

.d-flex {
  display: flex;
}

.input-card {
  margin: 0 0 15px;
  ion-input {
    --padding-top: 14px;
    --padding-bottom: 14px;
    font-size: 14px;
  }
  @include border-radius-single(50px);
  border: 1px solid #e4e4e4;
  @extend .box-shadow-blue;
  border: 1px solid #ddd;
  // box-shadow: 0px 0px 12px -6px #ccc;
}

.btn--with-lavel {
  padding: 0;
  @include border-radius-single(15px);
  ion-item {
    --background: transparent;
    color: var(--ion-color-dark);
    font-weight: 400;
    ion-label {
      --color: var(--ion-color-primary) !important;
    }
    ion-icon {
      background: var(--ion-color-primary);
      color: #fff;
      padding: 6px;
      font-size: 28px;
      @include border-radius-single(13px);
      @extend .box-shadow-blue;
    }
  }
  box-shadow: none;
  --background: rgba(var(--ion-color-primary-rgb), 0.07);
  --border-radious: 0px;
  margin-inline-start: 0px;
}
.btn--with-lavel-pico {
  padding: 0;
  @include border-radius-single(15px);
  ion-item {
    --background: transparent;
    color: var(--ion-color-tertiary);
    font-weight: 400;
    ion-label {
      --color: var(--ion-color-tertiary) !important;
    }
    ion-icon {
      background: var(--ion-color-tertiary);
      color: #fff;
      padding: 6px;
      font-size: 28px;
      @include border-radius-single(13px);
      @extend .box-shadow-blue;
    }
  }
  box-shadow: none;
  --background: rgba(var(--ion-color-tertiary-rgb), 0.07);
  --border-radious: 0px;
  margin-inline-start: 0px;
}

.btn--with-lavel-money-order {
  --background: rgba(var(--ion-color-tertiary-rgb), 0.07);
  @extend .btn--with-lavel;
  ion-item {
    ion-icon {
      background: var(--ion-color-tertiary);
      @extend .box-shadow-green;
    }
  }
}

ion-card {
  margin-left: 0;
  margin-right: 0;
}

ion-button {
  &[color="tertiary"] {
    // --box-shadow: 0px 4px 12px -1px rgba(var(--ion-color-tertiary-rgb), 0.5);
    --box-shadow: none;
  }
  @include phone-down {
    height: 50px;
  }
}
ion-button {
  &[color="primary"] {
    // --box-shadow: 0px 4px 12px -1px rgba(var(--ion-color-primary-rgb), 0.5);
    --box-shadow: 0px 4px 12px -1px rgba(var(--ion-color-step-300), 0.5);
  }
  text-transform: capitalize;
  height: auto;
  --padding-top: 14px;
  --padding-bottom: 14px;
}
ion-button {
  --border-radius: 5px;
  --padding-top: 14px;
  --padding-bottom: 14px;
  --padding-end: 40px;
  --padding-start: 40px;
  max-width: fit-content;
  &[size="small"] {
    --padding-top: 8px !important;
    --padding-bottom: 8px !important;
    --padding-end: 15px !important;
    --padding-start: 15px !important;
    font-size: 13px;
    ion-icon {
      font-size: 14px;
    }
  }
  text-transform: none;
  font-size: 14px;
  font-weight: 500;

  &::part(native) {
    flex-wrap: wrap !important;
  }
  &.ion-color-primary {
    // box-shadow: 0px 9px 18px -10px var(--ion-color-primary);
    // box-shadow: 0px 9px 18px -10px var(--ion-color-step-300);
    box-shadow: none;
    @include border-radius-s(90px, 90px, 90px, 90px);
    --ion-color-base: var(--green-color) !important;
    // min-height: 55px;
  }

  &.ion-color-light {
    --background: #fff;
    --ion-color-base: #fff !important;
    --box-shadow: 0px 3px 15px -9px var(--ion-color-step-200);
    --ion-color-contrast: var(--ion-color-primary) !important;
    @include border-radius-s(90px, 90px, 90px, 90px);
  }

  &.ion-margin-top-btn {
    // margin-top: 2em;
  }
}
.link-color {
  cursor: pointer;
}
// END BUTTONS

.picopay--theme,
.page-profile,
.page-add-pico-money-recipents {
  ion-input,
  .isd-class,
  ion-textarea,
  ion-datetime,
  ion-select {
    border: 1px solid rgba(112, 112, 112, 42%);
    border-radius: 5px !important;
    overflow: hidden;
    color: $text-color !important;
    .native-input {
      padding: 14px 14px;
      font-size: 14px;
      font-weight: 400;
      --placeholder-color: rgba(var(--ion-color-warning-contrast-rgb), 30%);
    }
    // box-shadow: 0px 3px 23px -15px var(--ion-color-primary);
    --background: #fff;
    height: auto;
    // border: 1px solid #ddd;
    margin-bottom: 15px;
    @include border-radius-s(90px, 90px, 90px, 90px);
  }
  ion-datetime {
    width: 100%;
    padding-left: 1rem;
    font-size: 0.9rem;
    height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  ion-textarea {
    padding-left: 1rem;
  }

  // END INPUT

  .light-text {
    color: var(--ion-color-step-50);
    font-size: 14px;
  }
}

.sub-header {
  margin-bottom: 2em;
  padding-top: 2rem;
  .heading--h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: bold;
    color: var(--black-color);
    @include tablet-down {
      font-size: 5.5vw;
    }
  }

  .heading--h3 {
    color: var(--ion-color-secondary);
  }

  .sub-heading--h4 {
    font-weight: 500;
    line-height: 1.5;
    font-size: 18px;
  }
  .sub-intro-text {
    font-size: 14px;
    line-height: 1.5;
    color: var(--darkgray-color);
    margin-bottom: 2em;
    font-weight: 500;
  }
}
ion-item {
  --color: var(--ion-item-color);
}

.card-listing {
  background-color: transparent;
  margin: 0;
  ion-item {
    margin-bottom: 10px;
    // box-shadow: 0 2px 11px -3px #ccc;
    font-size: 14px;
    font-weight: 400;
    --border-width: 0 !important;
    --border-radius: 10px !important;

    ion-checkbox {
      --border-radius: 50px !important;
      --border-color: var(--ion-color-primary);
      --size: 18px;
      --checkmark-width: 3px;
    }
  }
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 !important;
}
ion-header {
  ion-title {
    padding-inline-start: 0px;
    position: relative;
    font-size: 33px !important;
    text-align: center;
    margin-bottom: 0.5em !important;
    font-weight: bold !important;
    color: var(--black-color) !important;
    margin-bottom: 0 !important;

    ion-icon {
      padding-right: 10px;
    }
  }
}
.ios {
  ion-header {
    ion-title {
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      position: relative;
      // text-align: left;
      @media screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }
}

// Registration Page

.header-md::after {
  background: none;
}

// INTRO SLIDER
.into-slider {
  .swiper-pagination {
    bottom: auto;
    top: 94%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
  }
  .swiper-pagination-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 3px !important;
    opacity: 1;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .swiper-pagination-bullet-active {
    width: 16px;
    opacity: 1;
    background: $green-color !important;
  }
}

// EMAIL VERIFICATION
.codeverification {
  .resend-code {
    text-align: center;
    ion-text {
      font-size: 13px;
      text-decoration: underline;
    }
    p {
      margin: 0 0 8px;
      font-size: 13px;
      text-align: center;
      line-height: 1.5;
    }
  }
}

.picopay--theme.codeverification {
  .input-card ion-input {
    --padding-start: 1rem !important;
  }
  .sub-header {
    p {
      font-size: 14px;
      line-height: 1.5;
      @include phone-down {
        font-size: 16px;
        line-height: 1.5;
        color: var(--darkgray-color);
        font-weight: 500;
      }
    }
  }
  #email_otp {
    width: 60%;
    margin: auto;
    @include phone-down {
      width: 100%;
    }
    .inputs {
      ion-col {
        display: flex;
        align-items: center;
        justify-content: center;
        ion-input {
          .native-input {
            padding: 5px;
            font-size: 30px;
            font-weight: 600;
          }
          &:first-child {
            margin-left: 0;
          }

          --padding-top: 0;
          --padding-bottom: 0;
          --padding-start: 0;
          --padding-end: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 2px solid var(--darkgray-color);
          --background: transparent;
          --color: var(--darkgray-color);
          box-shadow: none;
          font-size: 30px;
          font-weight: 500;
          outline: none;
          text-align: center;
          margin-left: 10px;
          border-radius: 0 !important;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          -ms-border-radius: 0 !important;
          -o-border-radius: 0 !important;
          &.has-focus {
            border-bottom: 2px solid var(--green-color);
            --color: var(--green-color);
          }
        }
      }
    }
  }
}

.box-shadow--blue-theme {
  box-shadow: 0px 2px 26px -11px rgba(var(--ion-color-secondary-rgb), 0.3);
}

// New Theme Work
.picopay--theme-blue {
  --padding-bottom: 30px;
  --padding-top: 15px;
}

ion-button.back-btn {
  --padding-end: 0;
  --padding-start: 0;
}

.page-title {
  box-shadow: none;
  --background: none;
  padding: 0;
  margin: 0;
  ion-card-title {
    font-size: 16px;
    color: var(--ion-color-step-220);
    font-weight: 500;
  }
}

.form-row {
  box-shadow: none;
  @include border-radius-single(50px);
  // border: 1px solid #dedee8;
  ion-select {
    background-image: url(../assets/icon/chevron-down-outline.svg);
    background-repeat: no-repeat;
    background-position: right 0;
    background-size: 16px;
    &::part(icon) {
      opacity: 0;
    }
  }
  &.text-area {
    @include border-radius-single(20px);
  }
  &.form-button-row {
    padding: 0;
    border-width: 0;
    background: rgba(var(--ion-color-primary-rgb), 0);
    @include border-radius-single(0px);
    ion-button {
      --border-radius: 150px;
      height: 50px;
    }
  }
}

.moneyorder--theme-green {
  --padding-top: 20px;
  --padding-bottom: 20px;
}

.picopay--theme-blue {
  padding-top: 30px;
  .transfer-card-box {
    padding: 15px;
    margin: 0;
    .money-trans {
      display: flex;
      justify-content: space-between;
      margin: 10px 0px;
      h6 {
        &:last-child {
          text-align: right;
        }
        font-size: 14px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        .curr {
          font-size: 10px;
          // color: #3880ff;
        }
      }
      p {
        font-size: smaller;
      }
    }
    .border-tran {
      border-bottom: 0.5px solid rgb(209, 205, 205);
    }

    @include border-radius-single(20px);
    @extend .box-shadow-light;
    .tran-icon {
      @include border-radius-single(10px);
      padding: 5px;
      border-radius: 8px;
      box-shadow: 0px 1px 7px -2px #ccc;
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      color: var(--ion-color-primary);
    }
  }
  .recip-items {
    margin: 0;
    .heading1 {
      color: var(--ion-color-step-220);
      font-weight: 500;
      font-size: 14px;
    }
    .recip-name {
      display: flex;
      align-items: center;
      margin: 10px 0;
      .detail {
        margin: 0;
        font-size: small;
        h5 {
          margin: 0;
          padding: 0 0 5px;
        }
        span {
          font-size: smaller;
        }
      }
    }

    .money-total {
      margin-top: 15px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background-color: rgba(var(--ion-color-primary-rgb), 0.05);
      font-size: smaller;
      h6 {
        font-size: 14px;
      }
      span {
        font-size: 13px;
        color: var(--ion-color-primary);
      }
    }

    .money-total2 {
      display: flex;
      justify-content: space-between;
      background-color: rgba(var(--ion-color-primary-rgb), 0.09);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px;
      p {
        font-weight: 800;
        color: var(--ion-color-secondary);
        font-size: 14px;
      }
      h6 {
        color: var(--ion-color-primary);
        font-size: 14px;
        font-weight: 900;
      }
    }

    ion-avatar {
      background: rgba(var(--ion-color-primary-rgb), 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: var(--ion-color-primary);
      @include border-radius-single(12px);
      font-weight: 500;
      border: 2px solid #f5f6fa;
      margin: 0 10px 0 0;
      padding: 5px;
      width: 45px;
      height: 45px;
    }
    h5 {
      font-size: 16px;
    }
  }

  .alerts {
    margin: 0;
    box-shadow: none;
    // --background: rgba(var(--ion-color-primary-rgb), 0.1);
    --background: rgba(var(--ion-color-primary-rgb));
    @include border-radius-single(15px);
    border: 1px solid var(--ion-color-primary);
    // --color: var(--ion-color-primary);
    --color: var(--ion-color-light);
    .ion-card-content {
      --color: var(--ion-color-primary);

      font-size: 10px;
      line-height: 1.5;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}
.popup-table-card {
  // overflow-y: auto;
  // @include border-radius-s(40px, 40px, 0, 0);
  @include border-radius-s(24px, 24px, 0, 0);
  // box-shadow: 0px 0px 21px 1px rgba(var(--ion-color-primary-rgb), 0.3);
  box-shadow: 0px 0px 41px -7px rgb(0 0 0 / 30%);
  ion-card-header {
    padding-top: 30px;
    padding-bottom: 30px;
    ion-card-title {
      color: var(--ion-color-step-50);
      font-weight: bold;
      text-align: center;
      font-size: 19px;
    }
    ion-card-subtitle {
      color: var(--ion-color-medium-shade);
      font-weight: 400;
      text-transform: uppercase;
      font-size: 13px;
      text-align: center;
    }
  }
  .popup-table-grid {
    ion-col {
      --ion-grid-column-padding: 10px;
      font-size: 14px;
      b {
        font-weight: 500;
        color: #000;
      }
      .currency {
        color: var(--ion-color-primary);
        padding-left: 5px;
        font-size: 14px;
      }
    }
  }
}

.btn-width-ion {
  ion-icon {
    margin-right: 15px;
  }
}

.ch-desk-messenger.rightPosition {
  width: 98% !important;
  right: 0px !important;
  left: 0;
  bottom: 6px !important;
  height: 97% !important;
}

// .Launcherstyled__Wrapper-oef45p-0{
//     right: 0 !important;
//     left: 0;
//     margin: 0 auto !important;
// }

.launcherIcon {
  margin-left: auto;
  bottom: 51px;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
  padding: 5px 15px 15px 15px;
  margin-top: -9px;
}

ion-header {
  ion-title {
    padding-left: 0px;
  }
  ion-buttons {
    ::part(native) {
      padding: 0;
      // max-width: 40px;
    }
    ion-button {
      margin: 0 !important;
    }
    margin: 0 !important;
    --padding-end: 0;
    --padding-start: 0;
  }
}
.content-cards {
  margin: 0;
  @include border-radius-single(15px);
  @extend .box-shadow--blue-theme;
  ion-card-header {
    ion-card-title {
      color: var(--ion-color-secondary);
    }
  }
}

.search-bar {
  --border-radius: 5px;
  border-color: rgba(112, 112, 112, 0.42);
  // --box-shadow: 0px 3px 14px -3px #ccc;
  --box-shadow: none;
  --icon-color: rgba(var(--ion-color-primary-rgb), 0.7);
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 15px;
}

.blog-card {
  margin: 0 0 15px;
  @extend .box-shadow-light;
  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
  ion-thumbnail {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 12px;
    height: 100%;
    width: 85px;
  }
  ion-card-content {
    padding: 7px 0 0px 0;
    width: 100%;
    ion-label {
      padding: 0 5px 5px 5px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      &.content-label {
        font-weight: 400;
        font-size: 14px;
      }
    }
    ion-col {
      padding: 0;
    }
    ion-button {
      .btn-text-wrap {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        ion-icon {
          margin-right: 5px;
          font-size: 19px;
        }
      }
      margin: 0;
      &.time {
        --padding-top: 5px;
        --padding-bottom: 5px;
        --padding-end: 10px;
        --padding-start: 10px;
        --color: var(--ion-color-dark);
        --background-hover: var(--ion-color-primary);
      }
      &.likes {
        --color: var(--ion-color-danger);
        --background-hover: var(--ion-color-danger);
      }
      --box-shadow: none;
      --background: transparent;
      --padding-top: 5px;
      --padding-bottom: 5px;
      --padding-end: 5px;
      --padding-start: 5px;
      --border-width: 5px;
    }
  }
}

.blog-card-detail {
  @extend .box-shadow-light;
  @include border-radius-single(15px);
  margin: 0;
  ion-card-subtitle {
    color: var(--ion-color-contrast);
    display: flex;
    align-items: center;
    ion-icon {
      padding: 5px;
      margin-left: 5px;
    }
  }
}

.map-container {
  @include border-radius-single(20px);
  @extend .box-shadow-blue;
  margin-bottom: 20px;
}
// ************************ SEND MONEY START***************************
.send-money-grid {
  ion-col {
    padding: 0;
    position: relative;
    .send-money-directions {
      max-width: 45px;
      position: absolute;
      z-index: 99;
      right: 45px;
      top: 52%;
      transform: translateY(-53%);
      -webkit-transform: translateY(-53%);
      -moz-transform: translateY(-53%);
      -ms-transform: translateY(-53%);
      -o-transform: translateY(-53%);
    }
  }
  .get-money {
    border-radius: 17px 17px 0 0 !important;
  }
  .send-money--card {
    padding: 10px 10px;
    margin: 5px 0 0 0;
    border-radius: 17px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius-single(17px);
    @extend .box-shadow-blue;
    .send-money--wraper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .send-amt {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
        ion-label {
          color: var(--darkgray-color);
          font-size: 0.87rem;
          text-align: left;
          font-weight: 500;
        }
        h2 {
          margin: 0;
          padding: 0;
          font-size: 26px;
          color: #333;
          font-weight: 700;
        }
        ion-input {
          @extend h2;
          --padding-start: 0;
          --padding-end: 0;
          --padding-top: 0;
          --padding-bottom: 0;
        }
      }

      .send-curr {
        min-width: 120px;
        padding: 5px 10px;

        ion-card {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          max-width: 100%;
          font-size: 12px;
          .arrow-btn {
            width: 13px;
            position: absolute;
            right: 9px;
          }
          ion-img {
            margin-right: 5px;
            max-width: 19px;
            &::part(image) {
              border-radius: 3px;
            }
          }
          margin: 0;
          background: rgba(var(--ion-color-primary-rgb), 0.08);
          box-shadow: none;
          width: 100%;
          padding: 3px 5px;
          text-align: center;
          color: var(--ion-color-primary);
          font-weight: 600;
          ion-select {
            padding: 0;
            &::part(icon) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.money-order {
  .send-money-grid {
    .send-money--card {
      @extend .box-shadow-green;
      .send-money--wraper {
        .send-curr {
          ion-card {
            margin: 0;
            background: rgba(var(--ion-color-tertiary-rgb), 0.08);
            color: var(--ion-color-tertiary);
            height: 34px;
            min-width: 113px;
          }
        }
      }
    }
  }
}

// **************** RATE EXCHANGE
.exchange-rate--card {
  padding: 15px;
  margin: 0;
  background: rgba(var(--ion-color-primary-rgb), 0.05);
  box-shadow: none;
  height: 110px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  @include border-radius-single(0);
  .cer {
    font-size: 16px;
    color: var(--black-color);
    font-weight: 600;
    display: block;
  }
  .cer-ammount {
    font-size: 18px;
    color: var(--green-color);
    font-weight: 600;
    display: block;
  }
  ion-list {
    padding: 0;
    background: none;
  }
  ion-item {
    --border-width: 0 !important;
    --background: none;
    --border-color: rgba(var(--ion-color-primary-rgb), 0.2);
    ion-text {
      // width: 100%;
      // text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: var(--green-color);
    }
    ion-label {
      // font-weight: 400;
      @extend ion-text;
      // font-size: 14px !important;
      font-size: 16px;
      color: var(--black-color);
      font-weight: 600;
      margin: 0;
    }
    --inner-padding-end: 0px;
    --padding-start: 0px;
  }
}

.money-order {
  .exchange-rate--card {
    // background: rgba(var(--ion-color-tertiary-rgb), 0.05);
    background: var(--green-color-100);
    ion-item {
      --border-color: rgba(var(--ion-color-tertiary-rgb), 0.2);
      // ion-text{
      //   color: var(--ion-color-tertiary);
      // }
    }
  }
}

// ***************** PAYMENT METHOD
form {
  width: 100%;
}
.payment-method-card {
  @include border-radius-single(20px);
  @extend .box-shadow-blue;
  margin: 0;
  padding: 20px;
  ion-card-title {
    font-size: 16px;
  }
  .pay-method {
    display: flex;
    align-items: center;
    .form-method {
      &:first-child {
        padding-right: 40px;
      }
      padding: 15px 0px 0px 0px;
      display: flex;
      align-items: center;
      ion-icon {
        font-size: 36px;
        color: var(--ion-color-primary);
        margin-left: 7px;
      }
      input[type="radio"] {
        -webkit-appearance: none;
        border-radius: 50%;
        outline: none;
        border: 3px solid rgba(var(--ion-color-primary-rgb), 0.5);
        width: 21px;
        height: 21px;
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          margin: 0% auto;
          border-radius: 50%;
        }

        &:checked:before {
          background: var(--ion-color-primary);
        }

        &:checked {
          background: rgba(var(--ion-color-primary-rgb), 0.3);
        }
      }
    }
  }
}

// ********************* TOTAL PAY
.total-pay--card {
  margin: 0;
  padding: 0px;
  box-shadow: none !important;
  @include border-radius-single(0 0 20px 20px);
  @extend .box-shadow-blue;
  ion-list {
    padding: 0;
    ion-item {
      // --padding-start:0px;
      // --inner-padding-end:0px;
      &.fee-item {
        --background: rgba(var(--ion-color-secondary-rgb), 0.05);
        .fee-charges {
          width: 100%;
          padding-right: 5px;
          ion-text {
            span {
              font-size: 14px;
              font-weight: 600;
            }
          }
          span {
            font-size: 14px;
          }
        }
      }

      &.total-pay-item {
        --background: var(--white-color) !important;
        // padding: 7px 0;
        .total-to-pay {
          width: 100%;
          padding: 25px 5px 25px 0px;
          h5 {
            margin: 0;
            // font-weight: 800;
            // font-size: 17px;
            // color: var(--ion-color-secondary);
            font-size: 1.6rem !important;
            font-weight: 700 !important;
            color: var(--darkgreen-color);
          }

          ion-text {
            span {
              font-size: 19px;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}

.money-order {
  .total-pay--card {
    @extend .box-shadow-blue;
    ion-list {
      padding: 0;
      ion-item {
        &.fee-item {
          --background: rgba(var(--ion-color-tertiary-rgb), 0.05);
        }

        &.total-pay-item {
          --background: rgba(var(--ion-color-tertiary-rgb), 0.1);
          display: flex;
          height: 110px;
        }
      }
    }
  }
}

.subject-grid {
  margin: 0 0 15px 0;
  padding: 0;

  .rate-text {
    width: 100%;
    text-align: right;
    font-size: 12px;
    color: var(--ion-color-danger);
  }
}

// ************************ SEND MONEY START***************************

.orders-wraper {
  margin: 0;
  padding: 0;
  .orders-row {
    margin: 0;
    padding: 0;
  }
  .orders-column {
    ion-icon {
      margin: 0;
      color: var(--ion-color-tertiary);
      background: #fff;
      @extend .box-shadow-light;
      border-radius: 6px;
      padding: 5px;
      display: flex;
      font-size: 19px;
      min-width: 22px;
    }
    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 600;
    }
    padding: 0;
    ion-card {
      margin-bottom: 20px;
      padding: 15px;
      @include border-radius-single(15px);
      @extend .box-shadow-green;
      p {
        margin: 0;
        &.para-two {
          font-size: 10px;
          padding-top: 2px;
          opacity: 0.9;
        }
        &.green-text {
          @include border-radius-single(6px);
          padding: 5px 8px;
          font-size: 12px;
          color: var(--ion-color-tertiary);
          background: rgba(var(--ion-color-tertiary-rgb), 0.07);
        }
      }
      ion-text {
        h3 {
          font-weight: bold;
          margin: 0;
          padding-right: 10px;
          font-size: 16px;
        }
      }

      &.deno-radio-button {
        .deno-radio-button--wrap {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          ion-item {
            --border-radius: 4px;
            flex: 1 1 1;
            ion-radio {
              --color: var(--ion-color-tertiary);
              --color-checked: var(--ion-color-tertiary);
              margin-right: 6px;
            }
          }
        }
      }

      &.gray-card {
        padding-bottom: 0;
        background-color: rgba(var(--ion-color-tertiary-rgb), 0.05);
        .all-total {
          ion-col {
            padding-top: 0;
            padding-bottom: 0;
          }
          h2 {
            font-weight: bold;
          }
          h3,
          h2 {
            margin: 10px 0;
            font-size: 19px;
          }
          background-color: rgba(0, 0, 0, 0.08);
          margin-top: 15px;
          margin-left: -15px;
          padding-left: 15px;
          margin-right: -15px;
          padding-right: 15px;
        }
      }
    }
  }
}

.account-details-with-flag {
  display: flex;
  // align-items: center;
  // justify-content: center;
  color: var(--ion-color-step-220);
  ion-img {
    margin-right: 5px;
    &::part(image) {
      max-width: 19px;
      @include border-radius-single(3px);
    }
  }
}
ion-alert .alert-message sc-ion-alert-md {
  --color: red !important;
}
.bold-style {
  font-weight: 600;
}
.codeverification .resend-code {
  margin: 0.5rem 0;
}

ion-button.button-solid {
  // box-shadow: 0px 3px 12px rgb(0 0 0 / 16%);
  // border-radius: var(--border-radius);
}
.link-color {
  color: var(--green-color) !important;
}
.text-semi-bold {
  font-weight: 600;
}
.no-shadow {
  box-shadow: none !important;
}
.light-blue-bg {
  background: #e5eaff;
}
.picopay--theme-blue .light-text {
  color: var(--ion-color-step-50);
}
.text-color-secondary {
  color: var(--ion-color-secondary);
}
.text-color-primary {
  color: var(--ion-color-primary);
}
.picopay--theme-green .pay-text {
  background: var(--ion-color-tertiary);
  color: #fff;
}
.picopay--theme-green ion-button::part(native) {
  background: var(--ion-color-tertiary);
  // min-height: 55px;
  font-size: 16px;
}
.shadow-black {
  box-shadow: 0px 3px 14px -3px rgba(var(--ion-color-step-rgb), 0.3);
}
.searchbar-search-icon.sc-ion-searchbar-md {
  color: var(--ion-color-step-220);
}
// .toolbar-title-default{
//   background-color: var(--page-bg-color);
// }
.searchbar-left-aligned.sc-ion-searchbar-ios-h
  .searchbar-search-icon.sc-ion-searchbar-ios {
  color: var(--ion-color-step-300);
  left: 9px;
  top: 3px;
}
.searchbar-input.sc-ion-searchbar-ios {
  background-color: #fff;
  padding: 21px 40px !important;
}
.branch-search .searchbar-search-icon.sc-ion-searchbar-ios {
  margin-left: 6px !important;
  top: 3px !important;
  color: var(--ion-color-step-220) !important;
}
.searchbar-search-icon.sc-ion-searchbar-ios {
  top: 3px;
  margin-left: 3px !important;
  color: var(--ion-color-step-220) !important;
}
.ng-pristine .searchbar-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px !important;
  border: 1px solid rgba(112, 112, 112, 0.42);
}
.country-search .searchbar-input {
  box-shadow: none;
}
.opt .ng-otp-input-wrapper {
  display: flex;
  justify-content: center;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  padding: 0;
}
.action-sheet-button.ion-activated.sc-ion-action-sheet-ios {
  background-color: #ccc;
}

.action-sheet-selected.sc-ion-action-sheet-ios .action-sheet-button-inner {
  background-color: var(--ion-color-step-500) !important;
}
@media screen and (min-width: 700px) and (max-width: 1400px) {
  .ipad-container {
    // width: 60% !important;
    // margin: 0 auto !important;
  }

  .ipad-middle {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mpin-code {
    margin-top: 40% !important;
  }
  .ipad-pt-40-per {
    --padding-top: 40%;
  }
  .ipad-pt-40-normal {
    padding-top: 40%;
  }
  .card-lists .card-list {
    // height: auto !important;
  }
  .bootom-list .card-list {
    height: 112px !important;
    max-height: 112px !important;
    padding: 10px 0;
  }
  .bootom-list .card-content img {
    min-height: 45px !important;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .card-lists {
    height: fit-content !important;
  }
  .bootom-list .card-content {
    padding: 10px 0 10px 0 !important;
  }
  .mck-close-btn {
    width: fit-content !important;
  }
  .transection-tab {
    margin: 0 1.2rem !important;
  }
  .search-by-date {
    padding: 0 0 0 18px !important;
  }
  .thank-text {
    width: 75%;
    margin: 0 auto;
  }
  .home-button {
    width: 40%;
    margin: 0 auto !important;
  }
  .circle-col {
    margin-top: 30% !important;
  }

  .Filter-page {
    --width: 58% !important;
    --height: 50% !important;
    padding-top: 0 !important;
  }
  .search-by-date .ipad-col-1 {
    flex: 0 0 calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
  }
  .search-by-date .ipad-col-2 {
    flex: 0 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
  }
  #map {
    // width: 96% !important;
  }
  .card-lists {
    height: calc(100vh - 410px) !important;
  }
  .page-instore {
    .card-lists {
      height: initial !important;
    }
  }
  .c-pop {
    height: auto !important;
  }
  .action-sheet-group.sc-ion-action-sheet-ios
    .action-sheet-title.sc-ion-action-sheet-ios:nth-child(1) {
    color: var(--ion-color-secondary);
    font-size: 16px;
  }
  .card-lists .card-list {
    height: auto !important;
  }
}
.btn-center {
  display: flex;
  justify-content: center;
  gap: 15px;
  @include phone-down {
    flex-direction: column;
  }
  ion-button.login--btn {
    @include phone-down {
      margin-top: 0 !important;
    }
  }
}
@media screen and (min-width: 700px) and (max-width: 1400px) and (orientation: portrait) {
  .slide--1 .slide-background-image {
    width: 63% !important;
    left: 16% !important;
    top: 0 !important;
  }
  ion-slides ion-button {
    width: 50% !important;
    top: 92% !important;
  }
  .slide--2 .slide-background-image {
    width: 100% !important;
    top: -21% !important;
  }
  .slide--3 .slide-background-image {
    top: -370px !important;
  }
  .slide--4 .slide-background-image {
    top: -250px !important;
  }
  .slide--3 .sl-body,
  .slide--4 .sl-body {
    top: 65% !important;
  }
  .slide--3 .sl-body p,
  .slide--4 .sl-body p {
    font-size: 1.4rem !important;
  }
  .Filter-page {
    --width: 58% !important;
    --height: 50% !important;
    padding-top: 0 !important;
  }
  .search-by-date .ipad-col-1 {
    flex: 0 0 calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(11 / var(--ion-grid-columns, 12)) * 100%) !important;
  }
  .search-by-date .ipad-col-2 {
    flex: 0 0 calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
    width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
    max-width: calc(calc(1 / var(--ion-grid-columns, 12)) * 100%) !important;
  }
  #map {
    width: 96% !important;
  }
  .continue-btn {
    width: 500px !important;
  }
}

@media screen and (min-device-width: 700px) and (max-device-width: 1400px) and (orientation: landscape) {
  .circle-col {
    margin-top: 10% !important;
  }
  .ipad-pt-40-normal {
    padding-top: 14%;
  }

  #map {
    width: 96% !important;
  }
  .branch-search #map {
    width: 100% !important;
  }
  .post-location-map#map,
  .post-location-map {
    width: 100% !important;
  }
  .slide--1 .slide-background-image {
    width: 46% !important;
    left: 25% !important;
    top: -160px !important;
  }
  .mpin-code {
    margin-top: 10% !important;
  }
  .ipad-pt-40-per {
    --padding-top: 2%;
  }
  .ipad-middle {
    height: 60vh;
  }
  .slide--3 .sl-body {
    // top: 18% !important;
  }
  .slide--3 .sl-body p,
  .slide--4 .sl-body p {
    // color: #fff !important;
  }
  .slide--3 .sl-body,
  .slide--4 .sl-body {
    width: 25% !important;
  }
  .slide--2 .slide-background-image {
    max-height: -webkit-fill-available !important;
    top: -3% !important;
  }
  .slide--3 .slide-background-image {
    // max-height: -webkit-fill-available !important;
    // top: -51% !important;
    height: inherit;
  }
  .slide--4 .slide-background-image {
    height: inherit;
  }
  .card-lists {
    margin-bottom: 100px !important;
  }
  .c-pop {
    height: auto !important;
  }
  .action-sheet-group.sc-ion-action-sheet-ios
    .action-sheet-title.sc-ion-action-sheet-ios:nth-child(1) {
    color: var(--ion-color-secondary);
    font-size: 16px;
  }
  .card-lists {
    height: fit-content !important;
  }
  .continue-btn {
    width: 500px !important;
  }
}
@media screen and (max-device-width: 1000px) and (orientation: landscape) {
  ion-slides ion-button {
    top: 88% !important;
  }
  .slide--2 .sl-head p {
    font-size: 10px !important;
  }
  .slide--1 .slide-background-image {
    width: 19% !important;
    left: 37% !important;
    top: 20px !important;
  }
  .mob-scroll-land {
    overflow: auto;
  }
  .Filter-page {
    --height: 88% !important;
  }
}
// @media screen and (max-width: 767px){
//   .kommunicate-iframe-enable-media-query{
//     top:40px !important;
//   }
// }

@media screen and (max-width: 600px) {
  // .my-custom-class .action-sheet-group .sc-ion-action-sheet-ios{
  //   color: var(--ion-color-secondary);
  //   font-size: 16px;
  //  }
  .my-custom-class .action-sheet-title.sc-ion-action-sheet-ios:nth-child(1) {
    color: var(--ion-color-secondary);
    font-size: 18px;
  }
}

ion-action-sheet.my-custom-class .action-sheet-title {
  font-size: 18px;
}
.store-list-select .form-row ion-select {
  background-position: right 11px;
}
.store-list-select ion-item .item-inner {
  border: 0;
}
.body-content {
  position: relative;
}
.body-content .ipad-container {
  background-color: var(--white-color);
  padding: 2.5rem;
  border-radius: 25px;
  box-shadow: 0 3px 99px 0px rgba(0, 0, 0, 7%);
  @media screen and (max-width: 600px) {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
}

.login-pages-container {
  max-width: calc(500px + 8%);
}

body.page-personal-reg {
  .body-content {
    @extend .login-pages-container;
  }
}

body.page-login, body.page-website-login {
  .body-content {
    @extend .login-pages-container;
  }
}
body.page-forgot-password {
  .body-content {
    @extend .login-pages-container;
  }
}
body.page-otp-verify,
body.page-mobile-otp,
body.page-email-otp,
body.page-reg-thankyou,
body.page-additional-info,
body.page-onfido-process {
  .body-content {
    @extend .login-pages-container;
  }
}

body[class*="page-thank-you"],body[class*="unsuccessful_payment"] {
  .body-content {
    @extend .login-pages-container;
  }
  .thank-text {
    width: 100%;
  }
}

body.page-bank-transfer {
  .body-content {
    @include phone-down {
      padding: 10% 0% 13%;
    }
  }
}
.body-content {
  max-width: calc(773px + 8%);
  padding: 4% 4% 8%;
  min-height: 200px;
  margin: 0 auto !important;
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    min-height: calc(100vh - 473px);
  }
  @include phone-down {
    padding: 10% 4% 13%;
  }
  &::after {
    content: "";
    background-image: url(../assets/img/page-bg.png);
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15rem;
    height: 15rem;
    z-index: -1;
    background-size: 100%;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  ion-content::part(background) {
    background: none !important;
  }
  ion-content {
    display: inline-block;
  }
  .content-wrap {
    width: 100%;
    max-width: 773px;
    margin: auto auto;
    position: initial;
    overflow: visible;
    contain: inline-size !important;
    @include phone-down {
      max-width: 100%;
    }
  }
  .content-wrap-630 {
    max-width: 630px;
    @include phone-down {
      width: 100%;
    }
  }
  .height-100-per {
    height: 100%;
  }
  .sub-header {
    width: 100%;
    margin: auto auto !important;
    position: relative;
  }
}
ion-button.login--btn {
  max-width: 150px !important;
  width: 150px;
  --box-shadow: none;
  letter-spacing: 0;
  --border-radius: 5px !important;
  --box-shadow: none !important;
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100% !important;
    height: 50px;
  }
}
.ion-color-secondary.outline-btn {
  --ion-color-base: transparent !important;
  border: 1px solid var(--green-color);
  border-radius: 5px;
  --ion-color-contrast: var(--green-color) !important;
  @include phone-down {
    background-color: var(--white-color);
  }
}

@media screen and (min-width: 1200px) {
  .continue-btn {
    width: 500px !important;
  }
  .desktop-center {
    width: 500px;
    margin: 0 auto;
  }
}
::placeholder {
  color: rgba(var(--ion-color-warning-contrast-rgb), 60%) !important;
  font-weight: 400 !important;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: rgba(var(--ion-color-warning-contrast-rgb), 60%) !important;
  font-weight: 400 !important;
}
.back-btn {
  width: 24px;
  // left: 0;
  // top: 50%;
  // position: absolute;
  padding: 0 !important;
}
// input[type="date"]{
//   appearance: none;
//   background-image: url(../assets/img/calendar2-event.png);
// }

// New Design
.alert-button.sc-ion-alert-md {
  color: var(--green-color);
}

.add-bg {
  background: #fff;
  padding: 4% 5%;
  @include border-radius-single(16px);
  @include phone-down {
    background: transparent;
    padding: 0;
  }
}
[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: var(--green-color);
}
.alert-radio-inner.sc-ion-alert-md {
  background: var(--green-color);
}
.form-input-design {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.19);
  @include border-radius-single(6px);
  min-height: 55px;
  padding: 5px 16px;
  font-size: 16px;
  color: #2e2e2e !important;
  &:disabled {
    background: rgba(0, 0, 0, 0.08);
    @include phone-down {
      background-color: var(--white-color);
    }
  }
  &::placeholder {
    color: #2e2e2e !important;
  }
}

.input {
  @extend .form-input-design;
}

body {
  ion-header {
    margin: 0 !important;
    background: transparent !important;
    padding-bottom: 1.5rem;
    padding-top: 0rem;
    ion-toolbar {
      --background: none !important;
    }
    ion-title {
      font-size: 2.5rem !important;
      text-align: center;
      font-family: var(--ion-font-family) !important;
      font-weight: 700 !important;
      padding: 0;
      @include tablet-down {
        font-size: 6.5vw !important;
      }
      @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 2rem !important;
        text-align: center !important;
      }
    }
  }
}

.button-center {
  margin-right: auto;
  margin-left: auto;
}

// bank transfer page
body.page-bank-transfer {
  .body-content {
    ::part(scroll) {
      @extend .add-bg;
      // background: #fff;
      // padding: 4% 5%;
      // @include border-radius-single(16px);
    }
    ion-card {
      @include border-radius-single(0);
      margin-left: -6%;
      margin-right: -6%;
      margin-bottom: 2rem;
      box-shadow: none;
      width: 112%;
      margin-bottom: 2rem;
      box-shadow: none;
      @include phone-down {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .pay-text {
        padding: 20px 5%;
        font-size: 16px;
        text-align: center;
        line-height: 1.5;
      }
    }
  }
  ion-col {
    h6 {
      font-size: 1.5rem !important;
    }
  }
  .pay-form {
    ion-button {
      @extend .button-center;
      margin-top: 2rem;
    }
  }
}

// page-your-order
body.page-your-order {
  .orders-wraper .orders-column ion-card {
    margin: 10px 0;

    &.first-card {
      ion-col {
        h3 {
          font-size: 1.5rem;
          font-weight: 800;
        }
        h4 {
          font-size: 1.3rem;
          font-weight: 800;
          color: $text-color;
        }
        .para-two {
          font-size: 15px;
        }
      }
      ion-icon {
        background: var(--ion-picomoney-color);
        color: var(--light-white-color);
      }
    }
    &.deno-radio-button {
      @include border-radius-s(15px, 15px, 0, 0);
      margin-bottom: 0;
      ion-select {
        border: 1px solid rgba(0, 0, 0, 0.19);
        font-size: 15px;
        font-weight: 500;
        @include border-radius-single(6px);
        padding-right: 1rem;

        .select-icon-inner {
          border: 0 !important;
        }
        // .select-text{
        //   font-size: 14px;
        // }
      }
      ::part(icon) {
        border: 0;
      }
      ::part(placeholder) {
        opacity: 1;
      }

      ion-list-header {
        padding: 0;
        ion-label {
          font-size: 1.2rem;
          padding-right: 5px;
        }
      }
    }
    &.gray-card {
      margin-top: 0;

      .all-total {
        padding-top: 15px;
        padding-bottom: 15px;
        background: var(--light-white-color);
        ion-col {
          &:last-child {
            h2 {
              font-size: 1.5rem;
              font-weight: 800 !important;
            }
          }
          &:first-child {
            h3 {
              font-size: 1.2rem !important;
              font-weight: 700 !important;
              color: var(--ion-picomoney-color) !important;
            }
          }
        }
      }
    }
  }
  .address-box {
    h5 {
      font-size: 1.2rem !important;
    }
  }

  ion-button {
    @extend .button-center;
  }
  .custom-select {
    padding: 0.5rem;
    height: auto;
  }
}

.thankyou-page {
  // &::part(scroll) {
  //   @extend .add-bg;
  //   padding: 9% 5% !important;
  //   }
  // &::part(background){
  //   background: none;
  // };
  .circle-col {
    margin-top: 0 !important;
    padding: 0 !important;
  }
}

.page-stripe-native, .page-online-transfer {
  .picopay--theme-green {
    @extend .add-bg;
    .alerts {
      border-radius: 0;
      margin-left: -5.5%;
      margin-right: -5.5%;
      margin-bottom: 2rem;
      box-shadow: none;
      width: 109%;
      margin-bottom: 2rem;
      box-shadow: none;
      @include phone-down {
        margin-top: 0;
        margin-bottom: 0;
        width: calc(100% + 24px);
        margin-right: -12px;
        margin-left: -12px;
      }
    }
    .pay-text {
      padding: 20px 5%;
      font-size: 16px;
      text-align: center;
      line-height: 1.5;
    }
  }
}
.form-label {
  color: rgba(var(--ion-color-step-rgb), 68%);
}
.page-add-pico-money-recipents {
  .form-row {
    border-radius: 0;
  }
  ion-datetime {
    &::part(placeholder) {
      color: rgba(var(--ion-color-warning-contrast-rgb), 30%) !important;
    }
  }
}
.page-recipients {
  .action-sheet-container {
    justify-content: center;
  }
  .action-sheet-group {
    border-radius: 12px 12px 0 0;
  }
  .action-sheet-group-cancel {
    border-radius: 0 0 12px 12px;
  }
}
.page-add-pico-money-recipents {
  ion-input,
  ion-textarea {
    margin-top: 0;
    margin-bottom: 0;
  }
  .item-interactive-disabled {
    ion-input,
    ion-select,
    ion-textarea,
    ion-datetime,
    .native-input,
    .native-textarea {
      @media screen and (min-width: 600px) {
        border-width: 0 0 1px 0;
        border-color: rgba(var(--ion-color-warning-contrast-rgb), 0.1);
        border-radius: 0 !important;
        padding-left: 0 !important;
        --color: var(--black-color) !important;
        opacity: 1 !important;
        height: 45px !important;
        font-weight: 500 !important;
        font-size: 16px;
      }
      @include phone-down {
        --color: var(--black-color) !important;
        opacity: 1 !important;
        font-size: 16px;
      }
    }
    .country_select {
      border-width: 0 0 1px 0 !important;
      border-color: rgba(var(--ion-color-warning-contrast-rgb), 0.2);
      border-radius: 0 !important;
      color: var(--black-color) !important;
      height: 2.85rem;
      font-size: 15px;
      // font-weight: 500;
      @include phone-down {
        height: 3.25rem;
      }
    }
  }
}
.mob-input {
  ion-input {
    border-left: 0;
    border-radius: 0 5px 5px 0 !important;
  }
  .country_select {
    border: 1px solid rgba(112, 112, 112, 0.42);
    padding: 0 10px !important;
    height: 3.16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    color: var(--green-color);
  }
}

.error-msg {
  color: red;
  font-size: 14px;
  padding: 5px 15px 15px 15px;
  margin-top: -9px;
}

.card-content-md {
  font-size: 16px;
  font-weight: 500;
}

.content-cards {
  padding: 2%;
  ion-card-header {
    display: none;
  }
}

.modal-wrapper {
  ion-header {
    background: $white-color !important;
    padding-bottom: 0;
  }
  ion-content {
    &::part(scroll) {
      position: absolute !important;
    }
  }
}
.page-forgot-change-password {
  .form-control {
    border: 0;
    border-radius: 10px;
    border: 1px solid rgba(112, 112, 112, 42%);
    box-shadow: none;
    --background: var(--white-color);
    overflow: hidden;
    --padding-start: 10px !important;
  }
  .input-card {
    border-radius: 10px;
    border: 1px solid rgba(112, 112, 112, 42%);
    box-shadow: none;
    --background: none;
    border: 0;

    ion-item {
      --background: none;
      border: 0;
      --padding-start: 0;
      --inner-padding-end: 0;
    }
  }
}
.page-forgot-change-password .form-control {
  font-size: 16px;
  line-height: 25px;
  min-height: 55px;
}
.page-send-pico-money-recipents {
  .action-sheet-container {
    justify-content: center;
  }
}
.page-post-location ion-modal {
  height: 100%;
}

body.page-transaction {
  ion-modal {
    --width: 90% !important;
    --height: 100% !important;
    padding-top: 0 !important;
    @include phone-down {
      --height: 95% !important;
    }
  }
}

ion-content {
  ion-button {
    @include phone-down {
      max-width: 100%;
      --width: 100%;
    }
  }
}

.btn-wraper {
  @include phone-down {
    max-width: 100%;
  }
}
.mob-forget-link {
  @include phone-down {
    display: flex;
    flex-direction: column;
  }
  ion-col {
    @include phone-down {
      text-align: center !important;
      margin-bottom: 1.2rem;
    }
  }
}
ion-button.login--btn {
  @include phone-down {
    width: 100% !important;
  }
}
.page-forgot-change-password {
  .input-card {
    ion-item {
      @include phone-down {
        // --padding-start: 0;
        // --inner-padding-end: 0;
        // --padding-top: 0;
        // --inner-padding-top: 0;
      }
    }
  }
  .forgot-password {
    @include phone-down {
      width: 100%;
      height: 50px;
    }
  }
}
.page-onfido-process {
  .form-control {
    @include phone-down {
      height: 50px;
    }
  }
}
.backdrop-no-scroll .ion-page {
  //  overflow: hidden !important;
  @include phone-down {
    overflow-y: auto !important;
  }
}

[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios,
[aria-checked="true"].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios,
.alert-button.sc-ion-alert-ios {
  color: $green-color;
  border-color: $green-color;
}

body.page-transaction {
  .search-by-date {
    ion-row {
      ion-col {
        &:not(:last-child) {
          .search-bar.ios {
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .searchbar-input-container.sc-ion-searchbar-ios {
              height: 40px;
            }
          }
        }
      }
    }
  }
}

.modal-wrapper {
  &.modal-wrapper.sc-ion-modal-ios,
  &.modal-shadow.sc-ion-modal-ios {
    @include border-radius(1rem);
  }
  ion-button {
    @include phone-down {
      min-width: 100%;
    }
  }
}

.page-add-pico-money-recipents {
  ion-card {
    @include phone-down {
      --background: none;
    }
  }
  @include phone-down {
    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      --background: none;
    }
  }
  ion-select,
  ion-datetime,
  .country_select {
    @include phone-down {
      background-color: var(--white-color);
      background-position: 97% 16px !important;
    }
  }
  ion-select {
    @include phone-down {
      margin-bottom: 0;
    }
  }
  ion-card-header {
    @include phone-down {
      padding-left: 0;
    }
  }
}
.page-instore {
  .card-lists {
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      height: auto !important;
    }
  }
}
.page-forgot-password {
  .input-card ion-input {
    --padding-start: 1rem;
  }
}
.page-add-pico-money-recipents {
  .picker-wrapper {
    @media screen and (min-width: 600px) {
      top: 0;
      border-radius: 15px;
    }

    .picker-button,
    .picker-opt-selected {
      color: var(--green-color);
    }
  }
}
.page-add-pico-money-recipents {
  .add-reciepents {
    .form-control {
      height: initial !important;
      padding: 14px !important;
      &.custom-date {
        @include border-radius(5px 0px 0px 5px);
      }
    }
  }
}
.form-control {
  height: initial !important;
  // padding: 14px !important;
}
.custom-select,
.custom-date {
  border: 1px solid rgba(112, 112, 112, 0.42);
  border-radius: 5px !important;
  overflow: hidden;
  --background: #fff;
  padding: 0.5rem 1rem;
  height: 34px;
  color: rgba(var(--ion-color-warning-contrast-rgb), 40%) !important;
  font-weight: 400 !important;
  font-size: 14px;
  appearance: none;
  -webkit-appearance: none;
}

.form-control.custom-select {
  padding-right: 16px !important;
  background-image: url(../assets/images/arrow.svg);
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 97% center;
}
.page-recipients,
.page-send-pico-money-recipents,
.page-money-order,
.page-add-pico-money-recipents {
  ion-item {
    overflow: visible;
  }
  ion-card {
    overflow: visible;
  }
  ion-card::part(native) {
    position: static;
  }
  ion-item::part(native) {
    position: static;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .c-dd {
    margin-right: 0.5rem;
  }
}

.dropdown {
  .dropdown-menu {
    padding-right: 3px;
    width: fit-content;
    left: auto !important;
    right: 0;
    padding-right: 3px;
    z-index: 9999 !important;
    box-shadow: 0px 0px 20px -8px #333;
    border: 1px solid #ddd;

    .select-country {
      max-height: 170px;
      overflow: auto;
      background: #fff;
      &::-webkit-scrollbar {
        width: 0.3em;
      }
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 0;
        border-radius: 5px;
        padding-right: 10px;
      }
    }
  }
}
.select-country {
  .select-heading {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.9rem;
    margin: 0;
    color: $green-color;
    &:not(:first-child) {
      padding-top: 10px;
    }
  }
}

.domination-style {
  ng-select {
    .ng-placeholder {
      color: #999;
      font-size: 13px;
      font-weight: 500;
    }
    .ng-value {
      color: #999;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.ios {
  .domination-style {
    ng-select {
      .ng-input {
        top: initial !important;
      }

      .ng-arrow-wrapper {
        display: flex;
        align-items: baseline;
        justify-content: center;
      }
    }
  }
}

.page-money-order {
  ion-content {
    .subject-grid {
      margin: 0px 0 15px 0;
      padding: 0;
      background: #fff;
      min-height: 11px;
      @include border-radius(0px 0px 18px 18px);
    }
  }
}

.custom-input-grp {
  .ng-select.ng-select-single {
    .ng-select-container {
      height: 100% !important;
      border-width: 0px 1px 0 0px;
      @include border-radius(0px);
    }
  }
}

.toggle-password {
  position: relative;
  ion-input {
    padding-right: 45px !important;
  }
  .type-toggle {
    position: absolute;
    right: 15px;
    z-index: 99;
    top: calc(50% - 16px);
    ion-icon {
      color: $green-color;
    }
  }
}

#onfido-mount {
  .onfido-sdk-ui-CountrySelector-CountryDropdown-custom__menu {
    position: relative;
  }
  // .onfido-sdk-ui-Theme-scrollableContent {
  //   min-height: 50vh;
  // }

  .onfido-sdk-ui-Modal-inner {
    position: relative !important;
    height: auto !important;
    max-height: fit-content !important;
  }

  .onfido-sdk-ui-Uploader-contentWrapper,
  .onfido-sdk-ui-Uploader-uploaderWrapper {
    height: fit-content;
  }
  .onfido-sdk-ui-Theme-iconContainer,
  .onfido-sdk-ui-CameraPermissions-New-Primer-image {
    min-height: fit-content;
    max-height: fit-content;
  }
  .onfido-sdk-ui-EnlargedPreview-imageContainer,
  .onfido-sdk-ui-EnlargedPreview-expanded {
    position: relative;
  }
}
.send-money-grid {
  .send-money--card {
    .send-money--wraper {
      .send-amt {
        ion-input {
          input {
            border: 1px solid #ddd;
            @include border-radius(5px);
            padding: 3px 10px;
            max-width: 200px;
            &:focus {
              border: 1px solid $green-color;
            }
          }

          ion-label {
            padding: 0px 10px;
          }
        }
      }
    }
  }
}

// .page-stripe-native {
//   .ion-page {
//     overflow-y: auto;
//     overflow-x: hidden;
//   }
// }
@media screen and (max-width: 480px) {
  .fssteps-box {
    .text-black {
      span.br {
        display: block;
      }
    }
  }
  body.page-your-order
    .orders-wraper
    .orders-column
    ion-card.deno-radio-button
    ion-list-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    ion-label {
      padding-bottom: 5px;
    }
    ion-label,
    ng-select {
      flex: 1 0 100%;
      width: 100% !important;
    }
  }

  .domination-style ng-select .ng-placeholder {
    font-size: 16px;
  }
}
#onfido-mount {
  .onfido-sdk-ui-Camera-camera {
    min-height: 490px;
  }
}
// @media screen and (max-width: 480px) {
//   body.page-transaction {
//     ::part(scroll) {
//       padding-bottom: 200px !important;
//     }
//   }
// }

.drop-box .dropdown-item {
  white-space: initial !important;
  min-width: 280px;
}

.page-change-password {
  form {
    ion-card {
      background: none;
    }
  }
  .bg-white {
    @include phablet-down {
      background-color: transparent !important;
    }
  }
}

app-email-verification,
app-phone-verification {
  ion-button.login--btn {
    margin: auto;
  }

  .sub-header {
    padding-top: 0 !important;
  }
  ion-header ion-toolbar:first-of-type {
    --padding-end: 20px;
    --padding-start: 20px;
  }
}

app-pico-money-transaction-details {
  ion-content {
    overflow-y: auto;
  }
}

html.ios {
  app-add-pico-money-recipents {
    .nominee-dob-card {
      position: relative;
      z-index: 1;
    }
  }
}

.page-personal-reg {
  .toggle-password {
    .form-control.is-invalid {
      + .type-toggle {
        ion-icon {
          display: none !important;
        }
      }
    }
  }
}
