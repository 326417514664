:root {
  --darkblue-color: #010248;
  --lightblue-color: #0036ff;
  --blue-color-50: #e5eafe;
  --blue-color-100: #143336;
  // --light-grey-color:#f2f2f2;
  --light-grey-color: #f7f7f9;
  --light-white-color: #fff;
  --darkgray-color: #2e2e2e;
  --grey-color-50: #787878;
  --grey-color-100: #707070;
  --grey-color-150: #d3d3d3;
  --darkgreen-color: #003437;
  --light-green-color: #00ff86;
  --green-color: #008979;
  --green-color-50: #2df484;
  --green-color-100: #edf7f6;
  --green-color-150: #a4a4a4;
  --grey-color-200: #e8e8e8;
  --white-color: #ffffff;
  --black-color: #000;
  --grey-color: #e9e9e9;
}
$primary-font: "Montserrat", sans-serif;
$white-color: #ffffff;
$text-color: #2e2e2e;
$dark-grey-color: #646464;
$fade-color: #dedee8;
$heading-color: #000;
$green-color: #008979;
$dark-green-color: #003437;

@mixin border-radius($pixel...) {
  border-radius: $pixel !important;
  -webkit-border-radius: $pixel !important;
  -moz-border-radius: $pixel !important;
  -ms-border-radius: $pixel !important;
  -o-border-radius: $pixel !important;
}
@mixin transition($pixel...) {
  transition: $pixel !important;
}
.primary-btn {
  min-height: 55px;
  --border-radius: 6px;
  --color: #fff;
  --background: #008979;
  font-size: 16px;
  &::part(native) {
    color: $white-color;
    @include border-radius(6px);
  }
}

/*  Media query variables */
$xxs_small_phone_width: 351px;
$xs_small_phone_width: 391px;
$phone_width: 481px;
$phablet_width: 667px;
$ipad_width: 820px;
$tablet_width: 768px;
$desktop_width: 992px;
$desktop_mid_width: 1024px;
$container_width: 1200px;
$small-desktop-width: 1366px;
$large_desktop_width: 1400px;
$landscape: landscape;
$portrait: portrait;
/*
 * Mobile landscape media query
 */

/* landscape */
@mixin landscape-screen {
  @media only screen and (max-width: #{$desktop_width}) and (orientation: #{$landscape}) {
    @content;
  }
}
/* Portrait */
@mixin portrait-screen {
  @media only screen and (max-width: #{$tablet_width}) and (orientation: #{$portrait}) {
    @content;
  }
}
@mixin landscape-screen-for-992 {
  @media only screen and (max-width: #{$desktop_width}) and (orientation: #{$landscape}) {
    @content;
  }
}

/* (mini-tab) */
@mixin min-tab {
  @media only screen and (min-width: #{$tablet_width}) and (max-width: #{$desktop_width}) {
    @content;
  }
}

/* (max-width:1200px) */
@mixin container-down {
  @media only screen and (max-width: #{$container_width}) {
    @content;
  }
}

/* (min-width:1200px) */
@mixin container-up {
  @media only screen and (min-width: #{$container_width}) {
    @content;
  }
}

/* (min-width:1400px) */
@mixin large-desktop {
  @media only screen and (min-width: #{$large_desktop_width}) {
    @content;
  }
}
/* (min-width:1181px)-(max-width:1399px) */
@mixin small-desktop {
  @media only screen and (min-width: #{$desktop_mid_width}) {
    @content;
  }
}
@mixin small-desktop-down {
  @media only screen and (max-width: #{$desktop_mid_width - 1px}) {
    @content;
  }
}
/* (min-width:1024px)-(max-width:1280px) */
@mixin mini-desktop {
  @media only screen and (min-width: #{$desktop_mid_width}) and (max-width: #{$container_width + 80px}) {
    @content;
  }
}

/* (max-width:1399px) */
@mixin large-desktop-down {
  @media only screen and (max-width: #{$large_desktop_width - 1px}) {
    @content;
  }
}

/* (max-width:1023px) */
@mixin desktop_mid_width {
  @media only screen and (max-width: #{$desktop_mid_width - 1px}) {
    @content;
  }
}
/* (max-width:1024px) */
@mixin max-tab {
  @media only screen and (max-width: #{$desktop_mid_width}) {
    @content;
  }
}
/* (max-width:1025px) */
@mixin desktop_mid_width_up {
  @media only screen and (min-width: #{$desktop_mid_width + 1px}) {
    @content;
  }
}

/* (max-width:991px) */
@mixin desktop-down {
  @media only screen and (max-width: #{$desktop_width - 1px}) {
    @content;
  }
}

/* (min-width:992px) */
@mixin desktop-up {
  @media only screen and (min-width: #{$desktop_width}) {
    @content;
  }
}

/* (max-width:768px) */
@mixin tablet-down {
  @media only screen and (max-width: #{$tablet_width}) {
    @content;
  }
}
/* (max-width:768px) */
@mixin tablet2-down {
  @media only screen and (max-width: #{$tablet_width - 1}) {
    @content;
  }
}

/* (max-width:820px) */
@mixin ipad-up {
  @media only screen and (min-width: #{$ipad_width + 1}) {
    @content;
  }
}
@mixin ipad-down {
  @media only screen and (max-width: #{$ipad_width}) {
    @content;
  }
}

/* (max-width:767px) */
@mixin min-tab-to-tab {
  @media only screen and (min-width: #{$tablet_width - 2px}) and (max-width: #{$desktop_width - 2px}) {
    @content;
  }
}

/* (min-width:768px) */
@mixin tablet-up {
  @media only screen and (min-width: #{$tablet_width + 1px}) {
    @content;
  }
}

/* (max-width:595px) */
@mixin phablet-down {
  @media only screen and (max-width: $phablet_width) {
    @content;
  }
}

/* (min-width:595px) */
@mixin phablet-up {
  @media only screen and (min-width: #{$phablet_width}) {
    @content;
  }
}

/*  (max-width:480px) */
@mixin phone-down {
  @media only screen and (max-width: #{$phone_width - 1px}) {
    @content;
  }
}

/*  (max-width:390px) */
@mixin xs-small-phone {
  @media only screen and (max-width: #{$xs_small_phone_width - 1px}) {
    @content;
  }
}
/*  (max-width:350px) */
@mixin xxs-small-phone {
  @media only screen and (max-width: #{$xxs_small_phone_width - 1px}) {
    @content;
  }
}
