/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import "./assets/scss/style.scss";

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/custom-style";
body,
html {
  scroll-behavior: auto !important;
}


.transaction-track-detail .modal-wrapper {
  --height: 85%;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  --border-radius: 10px;
  border: 1px solid #ededed;
  --background: #fff;
  background: #ffffff;
  overflow: auto;
}
.transaction-track-detail .modal-wrapper main.overscroll {
  overflow: auto !important;
  overflow-y: auto !important;
}
.post-date-component {
  --width: 100%;
  --height: 100%;
  margin-top: 20%;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #fff;
}
.calandar-ionic2 {
  td,
  th {
    border: 0 !important;
  }
  .swiper-slide {
    font-size: 13px;
  }
}
.Filter-page {
  --width: 98%;
  --height: 100%;
  padding-top: 25%;
  .modal-wrapper.sc-ion-modal-md {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
}
::ng-deeps div#alert-3-msg {
  color: #333;
}

.gvJyp {
  position: fixed;
  z-index: 10000000 !important;
  display: block !important;
  flex-wrap: nowrap !important;
  width: auto !important;
  overflow: visible !important;
  background: transparent !important;
  top: calc(101px + env(safe-area-inset-bottom)) !important;
  right: 14px !important;
  bottom: auto !important;
  display: none !important;
}

.action-sheet-button.sc-ion-action-sheet-ios {
  display: block;
  position: relative;
  width: 100%;
  border: 0;
  outline: none;
  background: #fff !important;
  color: #2e2e2e !important;
  font-family: inherit;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}
.action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: #fff !important;
  opacity: var(--button-background-selected-opacity);
}
.action-sheet-group .sc-ion-action-sheet-ios {
  background: #fff !important;
}
.toast-top {
}
::ng-deeps .toast-top {
  background: #fff;
  box-shadow: 1px 0px 12px 3px rgb(0 0 0 / 10%);
}
.toast-custom-class {
  color: #ff0000;
  --ion-color-base: #ffffff !important;
  --box-shadow: 4px 4px 11px 2px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  --border-radius: 30px;
}
ionic-selectable-modal ion-buttons {
  padding-left: 15px;
  padding-top: 5px;
}
@media only screen and (min-width: 665px) and (max-width: 1023px) {
  .Filter-page {
    padding-top: 0 !important;
  }
}
.ion-page {
  overflow: unset;
  contain: unset;
}
// .slick-track {
//   width: auto !important;
// }
// .slick-slide{
//   width: auto !important;
// }
.isd-class {
  .ng-select.ng-select-single .ng-select-container {
    width: 95px !important;
  }
}
.domination-style {
  .ng-select {
    width: 172px !important;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.custom-select,
.custom-date {
  color: #2e2e2e !important;
}
