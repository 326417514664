// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {  
  --ion-font-family:'Montserrat', sans-serif;
  --lightBlue-color: #00FFFF;
  --lightGreen-color: #00FF86;
  --ion-toolbar-background:#F7F7F9;
  --ion-item-color:#2e2e2e;
  --ion-picomoney-color:#003437;
  /** primary **/
  --ion-color-primary: #0036FF;
  --ion-color-primary-rgb: 0, 54, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #008979;
  --ion-color-secondary-rgb: 0, 137, 121;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #008979;
  --ion-color-secondary-tint: #008979;

  /** tertiary **/
  --ion-color-tertiary: #008979;
  --ion-color-tertiary-rgb: 0, 137, 121;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #06C5AF;
  --ion-color-tertiary-tint: #06C5AF;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #ffffff;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #F7F7F9;
  --ion-color-dark-contrast-rgb: 247, 247, 249;
  --ion-color-dark-shade: #ffffff;
  --ion-color-dark-tint: #ffffff;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #fff;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-blue: #E5EAFF;

  /** custom color **/
  --ion-color-step-rgb: 46,46,46;
  --ion-color-step-50: #2e2e2e;
  // --ion-color-step-100: #434343;
  --ion-color-step-100: #434343;
  --ion-color-step-150: #585858;
  --ion-color-step-200: #6d6d6d;
  --ion-color-step-220: #787878;
  --ion-color-step-250: #828282;
  --ion-color-step-300: #979797;
  --ion-color-step-350: #ababab;
  --ion-color-step-400: #c0c0c0;
  --ion-color-step-450: #d5d5d5;
  --ion-color-step-500: #eaeaea;
  // --ion-color-step-550: #ffffff;

  // Radiuas
  --radius-50:50px;

  // Shadow

  --box-shadow-one:0px 3px 14px -3px #ccc;

  // page background color
  --page-bg-color:#f0f0f2;



}

